<template>
  <div class="ServiceHall">
    <!-- 服务大厅 -->
    <div class="Hall">
      <div class="Hall-head">
        <div class="Hall-title">服务大厅</div>
        <div class="Hall-delete select" @click="hallDelete">
          <img src="@/assets/imgs/delete.png" alt class="Hall-deleteimg" />
          <div>清空筛选条件</div>
        </div>
      </div>
      <div class="Hall-list">
        <div class="Hall-items">
          <div class="Hall-list-title">国内平台:</div>
          <div class="flex-wrap">
            <div class="items" v-for="(item, index) in platformIn" :key="index"
                 :class="ServiceHallConfig.platformId === item.id ? 'items-active' : ''"
                 @click="clickServiceInfo(item)">
              <img :src="item.logoImg" alt />
              <!-- <div>{{ item.platformName }}</div> -->
            </div>
          </div>
        </div>
        <!--  -->
        <div class="Hall-items">
          <div class="Hall-list-title">跨境平台:</div>
          <div class="flex-wrap">
            <div class="items" v-for="(item, index) in platformOut" :key="index"
                 :class="ServiceHallConfig.platformId === item.id ? 'items-active' : ''"
                 @click="clickServiceInfo(item)">
              <img :src="item.logoImg" alt />
              <!-- <div>{{ item.platformName }}</div> -->
            </div>
          </div>
        </div>
        <!--  -->
        <div class="Hall-items" v-if="Platforms.Popular">
          <div class="Hall-list-title">热门服务:</div>
          <div class="flex-wrap">
            <div class="items items-border" :class="
                ServiceHallConfig.itemsPId === item.id ? 'items-active' : ''
              " v-for="(item, index) in Platforms.Popular" :key="index" @click="popularSwitch(item)">
              <div>{{ item.itemsName }}</div>
            </div>
          </div>
        </div>
        <!-- 筛选 部分 -->
        <div class="Sort-info">
          <div class="Hall-list-title">服务信息:</div>
          <div class="Sort-menu">
            <div class="Menu">
              <Popover placeholder="请选择平台" plantName="platformName" ref="Popover" :list="platformList"
                       @checkItem="switchPopover" />
            </div>
            <div class="Menu">
              <el-cascader placeholder="请选择地区" @change="sortChange" v-model="ServiceHallConfig.providerCity"
                           :options="cityOptions" :props="areaProps" filterable clearable></el-cascader>
            </div>
            <div class="Menu">
              <el-cascader placeholder="请选择类目" @change="sortChange" v-model="ServiceHallConfig.categoriesId"
                           :options="categoryOptions" filterable clearable></el-cascader>
            </div>
            <div class="Menu">
              <PopoverTwo placeholder="请选择服务" plantName="itemsName" ref="Popover2" :list="serviceList"
                          @GetOpen="getOpen" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 筛选 排序 -->
    <div class="Sort">
      <div class="Sort-info">
        <div class="items" v-for="(item, index) in Sort" :key="index" :class="SortIndex == index ? 'items_active' : ''"
             @click="sortSwitch(index)">
          {{ item.name }}
          <i v-if="item.icon" class="iconfont icon-jiantou-xia-cuxiantiao"></i>
        </div>
      </div>
    </div>
    <div class="Lists" id="Lists" v-loading="loading" element-loading-text="拼命加载中...">
      <!-- 列表 -->
      <el-row v-for="(page, index) of pages" :key="index" type="flex" :gutter="23">
        <el-col :span="6" v-for="(item) of page" :key="item.id">
          <list1 :items="item" @GetClick="getDetailsPage" :Show="false" :rule="Platforms.All"></list1>
        </el-col>
      </el-row>
      <!-- 暂无信息 -->
      <el-empty v-show="ServiceHallempty" :image="NoList" :image-size="70">
        <div class="empty">
          <!-- <el-button type="danger">刷新一下 试试</el-button> -->
        </div>
      </el-empty>
      <!-- 分页 -->
      <Pagination :total="total" :size="ServiceHallConfig.numPerPage" :page="ServiceHallConfig.pageNum"
                  @handlePage="pageChange" v-if="total > ServiceHallConfig.numPerPage" />
    </div>
  </div>
</template>
<script>
import list1 from "@/components/List/components/list1.vue";
import Pagination from "@/components/Pagination";
import Popover from "@/components/Popover/HallIndex.vue";
import PopoverTwo from "@/components/Popover/index2.vue";

//  list 后续做 loading 加载
export default {
  data() {
    return {
      Sort:         [
        {
          name: "综合排序",
          type: "all",
        },
        {
          name: "价格",
          icon: true,
          type: "asc",
        },
        {
          name: "销量",
          icon: true,
          type: "desc",
        },
      ],
      SortIndex:    0, //默认下标 为 0
      IndustryList: [],
      CategoryList: [],
      // 需求大厅数据
      ServiceHallList:   [],
      total:             0, //需求大厅   总数据/条
      last_page:         1, //需求大厅 总页面
      ServiceHallConfig: {
        pageNum:      1,
        numPerPage:   20,
        platformId:   null,
        itemsId:      null,
        providerCity: null,
        categoriesId: null,
        serviceTitle: null,
        itemsPId:     null,
      },
      // end
      // 平台信息
      Platform:  {
        PlatformList: [],
        CityList:     [],
        ServiceType:  [],
      },
      Platforms: {
        Domestic: [],
        Abroad:   [],
        Popular:  [],
        All:      [],
      },
      AllList:   [],
      //   加载
      loading:          true,
      ServiceHallempty: false,
      
      platList:     [],
      Popover2Show: true,
      NoList:       require("@/assets/imgs/no_hall.png"),
      areaProps:    {
        value: 'id',
        label: 'name'
      },
      
    };
  },
  components: {
    list1,
    Pagination,
    Popover,
    PopoverTwo,
  },
  created() {
    this.getPopularService();
    this.getRecommend();
  },
  mounted() {
    this.ConfigCount();
  },
  watch:    {
    queryTitle(newVal, oldVal) {
      if (oldVal !== newVal && newVal) {
        this.ServiceHallConfig.serviceTitle = newVal
      }
    },
    executeQuery(newVal) {
      if (newVal) {
        this.ConfigCount()
        console.log('resetServiceHallQueryExecutable')
        this.$store.commit('resetServiceHallQueryExecutable')
      }
    }
  },
  computed: {
    pages() {
      const pages = []
      this.ServiceHallList.forEach((item, index) => {
        const page = Math.floor(index / 4) // 代表4条为一行，随意更改
        if (!pages[page]) {
          pages[page] = []
        }
        pages[page].push(item)
      })
      return pages
    },
    platformIn() {
      return this.AllList.filter(item => item.platformType === 1)
    },
    platformOut() {
      return this.AllList.filter(item => item.platformType === 2)
    },
    cityOptions() {
      return this.$store.getters.getCity;
    },
    categoryOptions() {
      let config = this.$store.getters.getConfig;
      return config.serviceCategories
    },
    platformList() {
      let config = this.$store.getters.getConfig;
      return config.servicePlatformList
    },
    serviceList() {
      let config = this.$store.getters.getConfig;
      return config.serviceItemsList
    },
    queryTitle() {
      return this.$store.getters.getServiceHallQueryTitle;
    },
    executeQuery() {
      return this.$store.getters.getServiceHallQueryExecutable
    }
  },
  methods:  {
    ConfigCount() {
      // 接受参数
      // 类目参数
      if (this.$route.params.type == "categoriesId") {
        this.ServiceHallConfig.itemsId = this.$route.params.ids;
        // 给子组件 传递信息
        this.$refs.Popover2.Config.value = this.$route.params.value;
        this.$refs.Popover2.Config.ids = this.$route.params.ids;
        this.$refs.Popover2.Config.id = this.$route.params.id;
        // 弹框 打开 选中 状态
        this.Popover2Show = false;
      }
      else if (this.$route.params.type == "serviceTitle") {
        this.ServiceHallConfig.serviceTitle = this.$route.params.title;
      }
      this.getServiceHall();
    },
    sortSwitch(index) {
      this.SortIndex = index;
      this.resettingHall();
      // 调取服务大厅的数据
      this.getServiceHall();
    },
    getOpen(item) {
      this.ServiceHallConfig.itemsId = item.ids;
      // 调取服务大厅的数据
      this.getServiceHall();
    },
    // 获取 配置项
    getResver() {
      if (!this.$store.getters.getConfig) {
        return setTimeout(() => {
          this.getResver();
        }, 500);
      }
      let config = this.$store.getters.getConfig;
      
      this.$refs.Popover.list = config.servicePlatformList;
      this.$refs.Popover2.list = config.serviceItemsList;
      if (this.Popover2Show) {
        this.$refs.Popover2.activeName = config.serviceItemsList[0].itemsName;
      } else {
        this.getPopover2(this.$route.params.id);
      }
      // console.log(this.$refs.Popover2.list, "this.$refs.Popover2.list");
      // this.$refs.Popover.SortIndex = 0;
    },
    getPopover2(ids) {
      let serviceItemsList = this.$store.getters.getConfig.serviceItemsList;
      for (let x = 0; x < serviceItemsList.length; x++) {
        if (serviceItemsList[x].id == ids) {
          this.$refs.Popover2.activeName = serviceItemsList[x].itemsName;
        }
      }
      this.$refs.Popover2.Config.value = this.$route.params.value;
      this.$refs.Popover2.Config.ids = this.$route.params.ids;
      this.$refs.Popover2.Config.id = this.$route.params.id;
      this.$refs.Popover2.ListIndex = this.$route.params.ids;
    },
    // 获取省市 数据
    getCityOptions() {
      // const data = this.$store.getters.getCity;
      // console.log('city', data)
      // const pid = 1;
      // this.Platform.CityList = commonJs.cityFilter(data, pid);
      this.Platform.CityList = this.$store.getters.getCity;
    },
    // 获取 热门服务
    getPopularService() {
      this.$axios
          .get(`/app-api/hiring/service-items/recommend`, {
            params: {
              num: 7
            }
          })
          .then((res) => {
            if (res.data.code === 0) {
              let data = res.data.data;
              for (let x = 0; x < data.length; x++) {
                if (data[x].pid == 0 && data[x].recommend == 1) {
                  this.Platforms.Popular.push(data[x]);
                }
              }
              // this.Platforms.Popular = res.data.data;
            } else {
              this.Platform.Popular = [];
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 重置数据
    resettingList(data) {
      let arr = [];
      for (let x in data) {
        let obj = {
          id:   x,
          name: data[x],
        };
        arr.push(obj);
      }
      return arr;
    },
    // 重置需求大厅参数
    resettingHall() {
      this.ServiceHallConfig = {
        pageNum:      1,
        numPerPage:   60,
        platformId:   null,
        itemsId:      null,
        providerCity: null,
        categoriesId: null,
        serviceTitle: null,
        itemsPId:     null,
      };
      this.ServiceHallList = [];
    },
    sortChange() {
      // 清除数据
      // this.resettingHall()
      this.getServiceHall();
    },
    // 页面切换
    pageChange(val) {
      // 跳转到顶部
      let targetEle = document.querySelector("#Lists");
      window.scrollTo({
        top: targetEle.offsetTop,
        // behavior: "smooth",
      });
      this.ServiceHallConfig.pageNum = val;
      this.getServiceHall();
    },
    // 获取平台推荐
    getRecommend() {
      let url = "/app-api/hiring/service-platform/recommend";
      this.$axios
          .get(url)
          .then((res) => {
            if (res.data.code === 0) {
              this.AllList = res.data.data;
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    switchPopover(item) {
      this.resettingHall();
      this.ServiceHallConfig.platformId = item.id;
      //
      this.getServiceHall();
    },
    // 热门·服务
    popularSwitch(item) {
      this.ServiceHallConfig.pageNum = 1;
      // 数据换算
      // let serviceItemsList = this.$store.getters.getConfig.serviceItemsList;
      // for (let x = 0; x < serviceItemsList.length; x++) {
      //   if (serviceItemsList[x].id == item.pid) {
      //     this.$refs.Popover2.activeName = serviceItemsList[x].itemsName;
      //   }
      // }
      // this.$refs.Popover2.Config.value = item.itemsName;
      // this.$refs.Popover2.Config.ids = item.id;
      // this.$refs.Popover2.Config.id = item.pid;
      // this.$refs.Popover2.ListIndex = item.id;
      this.ServiceHallConfig.itemsPId = item.id;
      this.getServiceHall();
    },
    // 获取服务大厅的数据
    getServiceHall() {
      let url = `/app-api/hiring/push-service/hall?pageNo=${this.ServiceHallConfig.pageNum}&pageSize=${this.ServiceHallConfig.numPerPage}`;
      // 当拿到的不是综合排序
      if (this.SortIndex !== 0) {
        url +=
            this.SortIndex === 1
                ? `&priceDesc=true`
                : `&orderDesc=true`;
      }
      // 是否收到 其他页面 传过来的搜索关键词
      if (this.ServiceHallConfig.serviceTitle) {
        url += `&serviceTitle=${this.ServiceHallConfig.serviceTitle}`;
      }
      // 是否有选择筛选条件
      if (
          this.ServiceHallConfig.platformId ||
          this.ServiceHallConfig.itemsId ||
          this.ServiceHallConfig.providerCity ||
          this.ServiceHallConfig.categoriesId ||
          this.ServiceHallConfig.itemsPId
      ) {
        // 平台
        if (this.ServiceHallConfig.platformId) {
          url += `&platformId=${this.ServiceHallConfig.platformId}`;
        }
        // 所在地区
        if (this.ServiceHallConfig.providerCity && this.ServiceHallConfig.providerCity.length) {
          url += `&providerCity=${this.ServiceHallConfig.providerCity[1]}`;
        }
        // 服务类目
        if (+this.ServiceHallConfig.categoriesId) {
          // 处理数据 platformId
          url += `&categoriesId=${this.ServiceHallConfig.categoriesId}`;
        }
        // 服务项目
        if (this.ServiceHallConfig.itemsId) {
          // 处理数据 platformId
          url += `&itemsId=${this.ServiceHallConfig.itemsId}`;
        }
        if (this.ServiceHallConfig.itemsPId) {
          url += `&itemsPId=${this.ServiceHallConfig.itemsPId}`;
        }
      }
      this.loading = true;
      this.$axios
          .get(url)
          .then((res) => {
            if (res.data.code === 0) {
              // console.log(res);
              this.loading = false;
              if (!res.data.data.total) {
                this.ServiceHallempty = true;
                this.total = res.data.data.total;
                return (this.ServiceHallList = []);
              }
              this.ServiceHallempty = false;
              this.ServiceHallList = res.data.data.list;
              this.total = res.data.data.total;
              // this.last_page = res.data.data.pre_page;
            }
          })
          .catch((err) => {
            this.loading = false;
            return Promise.reject(err);
          });
    },
    // 点击· 热门服务
    clickServiceInfo(item) {
      this.ServiceHallConfig.pageNum = 1;
      this.ServiceHallConfig.platformId = item.id;
      this.$refs.Popover.config.value = item.platformName;
      this.$refs.Popover.config.id = item.id;
      // 进行筛选数据
      this.getServiceHall();
    },
    // 进入详情页面
    getDetailsPage(Ids) {
      this.$router.push({ path: "/ServiceHall/Details", query: { id: Ids } });
    },
    // 清除筛选信息
    hallDelete() {
      // this.$modal.msgSuccess("清除筛选成功!");
      this.$store.commit('clearServiceHallQueryTitle')
      this.ServiceHallList = [];
      this.ServiceHallConfig = {
        pageNum:      1,
        numPerPage:   60,
        platformId:   null,
        itemsId:      null,
        providerCity: null,
        categoriesId: null,
        serviceTitle: null,
        itemsPId:     null,
      };
      this.$refs.Popover.config.value = "";
      this.$refs.Popover.config.id = "";
      this.$refs.Popover2.Config.value = "";
      this.$refs.Popover2.Config.id = "";
      this.$refs.Popover2.Config.ids = "";
      // 进行筛选数据
      this.getServiceHall();
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-input__inner {
  height: 47px !important;
}

.el-row + .el-row {
  margin-top: 20px;
}

.Lists {
  min-height: 400px;
  
  .items {
    margin-right: 20px !important;
    
    &:nth-child(4n) {
      margin-right: 0 !important;
    }
  }
}

.ServiceHall {
  
  .Hall {
    background: #ffffff;
    
    .Hall-head {
      height: 69px;
      //
      width: 100%;
      padding: 0 28px;
      box-sizing: border-box;
      border-bottom: 1px solid #dfe3ea;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .Hall-title {
      color: #000000;
      font-size: 20px;
      line-height: 87px;
      font-weight: 700;
    }
    
    .Hall-delete {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 20px;
    }
    
    .Hall-delete .Hall-deleteimg {
      width: 18px;
      height: 20px;
      margin-right: 4px;
    }
    
    .Hall-delete div {
      font-weight: 400;
      color: #333333;
      line-height: 23px;
      font-size: 16px;
    }
    
    .Hall-list {
      padding: 0 28px 28px;
      box-sizing: border-box;
      
      .Hall-items {
        display: flex;
        margin-top: 24px;
      }
      
      .Hall-list-title {
        color: #999999;
        font-size: 20px;
        min-width: 110px;
        line-height: 58px;
      }
      
      .flex-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: 13px;
        
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    
    .items-border {
      border: 1px solid #e8e8e8;
    }
    
    .items {
      width: 161px;
      height: 58px;
      border-radius: 4px 4px 4px 4px;
      box-sizing: border-box;
      margin-right: 12px;
      cursor: pointer;
      
      &:nth-child(7n) {
        margin-right: 0 !important;
      }
      
      &:nth-child(n + 8) {
      }
      
      display: flex;
      justify-content: center;
      align-items: center;
      
      &:hover {
        border: 1px solid #fc3737;
        // box-sizing: border-box;
        div {
          font-weight: 700;
        }
      }
    }
    
    .items-active {
      border: 1px solid #fc3737;
      // box-sizing: border-box;
      div {
        font-weight: 700;
      }
    }
    
    .items-center {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    
    .Sort-info {
      display: flex;
      align-items: center;
      margin-top: 24px;
      
      .Sort-menu {
        display: flex;
        align-items: center;
        margin-left: 13px;
        
        // justify-content: space-between;
        .Menu {
          margin-right: 12px;
          width: 154px;
          height: 47px;
        }
        
        /deep/ .el-dropdown {
          width: 100%;
          height: 100%;
        }
        
        .Name {
          color: #999999;
          font-size: 20px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          
          .select {
            display: block;
          }
          
          i {
            display: block;
            // font-size: 25px !important;
          }
        }
      }
    }
  }
  
  .Sort {
    // position: sticky;
    // background: #ffffff;
    height: 69px;
    top: 88px;
    z-index: 88;
    margin-top: 30px;
    
    .Sort-info {
      margin: 0 auto;
      width: 1440px;
      display: flex;
      align-items: center;
      height: 100%;
      background: #ffffff;
      
      .items {
        color: #999999;
        font-size: 20px;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 30px;
        cursor: pointer;
        margin-right: 12px;
        
        &:hover {
          color: #ffffff;
          background: #fc3737;
          
          i {
            color: #ffffff !important;
          }
        }
        
        img {
          height: 17px;
          margin-left: 4px;
        }
        
        i {
          margin-left: 4px;
          font-size: 25px;
          color: #999999;
        }
      }
      
      .items_active {
        color: #ffffff;
        background: #fc3737;
        
        i {
          color: #ffffff !important;
        }
      }
    }
  }
  
  .Lists {
    margin: 30px auto 0;
  }
}
</style>
